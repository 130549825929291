<template>
  <div>
    <Header :headerIndex="4"></Header>
    <div class="container">
      <img class="g_top_banner" src="../assets/static/icon/icon_top_banner4.png" alt="" />
      <div class="case_wrap">
        <div class="case_inner max_1440">
          <div class="g-select-wrap">
            <el-select class="g-select mr30" v-model="seriesValue" placeholder="Select Product Series" @click.native="getserise" >
              <el-option @click.native="seriesclear" value="">Select Product Series</el-option>
              <el-option v-for="(item,index) in seriesOptions" :key="index" :label="item.name_en" :value="index" @click.native="getseriseid(item.id)"> </el-option>
            </el-select>
            <el-select class="g-select" v-model="colorValue" placeholder="Choose Color Model" @click.native="getcolor">
              <el-option @click.native="colorclear" value="">Choose Color Model</el-option>
              <el-option v-for="(item,index) in colorOptions" :key="index" :label="item.name_en" :value="item.name" @click.native="getcolorid(item.id)"> </el-option>
            </el-select>
          </div>

          <div class="g_case_list flex-box flex-wrap flex-col-start">
            <router-link :to="{path:'/CaseDetail',query:{caseid:item.id}}" class="case_item" v-for="(item,index) in caselist" :key="index">
              <!-- <img class="img" :src="item.fpicurl" alt="" /> -->
              <div class="img" :style="{'background-image':'url(' + item.fpicurl +')'}"></div>
              <div class="name m-ellipsis">{{item.title_en}}</div>
              <div class="date">{{item._posttime1}}</div>
              <div class="desc m-ellipsis-l2">{{item.content_en}}</div>
              <div class="flex-box flex-between">
                <img class="logo" :src="item.shop_logo" alt="" />
                <div class="btn"></div>
              </div>
            </router-link>
          </div>

          <div :style="{display:display}">
            <Pagination :sumCount="total" :now="page" :perCount="per_page" @pageTurn="pageTurn"></Pagination>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import axios from 'axios';
import api from '../api';

export default {
  components: {},
  data() {
    return {
      seriesOptions: [
  
      ],
      seriesValue: "",

      colorOptions: [

      ],
      colorValue: "",
      colorid:0,
      seriesid:0,
      total: 1,  //页面总量
      per_page: 8,    //页面限制
      page:1,  //页面页数
      caselist:[],
      display:''
    };
  },
  methods: {
    pageTurn(page) {
      console.log(page)
      this.page = page+1;
      var json = {
        page:this.page,
        limit:this.per_page,
        typepid:this.seriesid,
        typeid:this.colorid
      }
      axios.post('https://n.xilukeji.com/rwf/index.php/Shop/Shop/community_2023',json).then(res=>{
          // console.log(res)
          if(res.data.count == 0){
            this.caselist = []
            this.total = 1
          }else{
            this.caselist = res.data.info
            this.total = parseInt(res.data.count)
          }  
          if(this.total<=this.per_page){
              this.display = 'none'
          }else{
            this.display = 'block'
          }      
      })
    },
    //获取series列表
    getserise(){
      // if()
      axios.get('https://n.xilukeji.com/rwf/index.php/Shop/Index/typelist_2023').then((res)=>{
          // console.log(res)
          if(res.status){
            this.seriesOptions=res.data.data.type
            // console.log('series',this.seriesOptions)
          }         
      })
    },
    //获取颜色列表
    getcolor(){ 
      if(this.seriesid>0&&this.seriesValue !== ''){
        // console.log(this.seriesValue)
        this.colorOptions = this.seriesOptions[this.seriesValue].child
      }else{
        this.colorOptions = []
        this.colorValue='Choose Color Model'
      }
    },
    getseriseid(id){
        this.seriesid = id
        this.page=1
        // console.log('this.page',this.page);
        this.colorValue =''
        this.colorid = 0  
        this.getcaselist()
    },
    seriesclear(){
      this.seriesid = 0   //typepid = 0
      this.colorid = 0    //typeid = 0
      this.seriesValue = ''
      this.getcolor()
      this.getcaselist()
    },  
    getcolorid(id){
      // console.log('colorid',id)
      this.colorid = id
      this.page =1
      this.getcaselist()
    },
    colorclear(){
      this.colorid = 0,
      this.colorValue = ''
      this.getcaselist()
    },
    //获取案例的列表
    getcaselist(){
      this.pageTurn(0)
    }
  },
  created(){
    //初始化加载
    if(this.$route.query.typepid&&this.$route.query.typeid){
      var json = {
        page:this.page,
        limit:this.per_page,
        typepid:this.$route.query.typepid,
        typeid:this.$route.query.typeid,
        indexrecommend:0
      }
      axios.get('https://n.xilukeji.com/rwf/index.php/Shop/Index/typelist_2023').then((res)=>{
          console.log('1111',res)
          if(res.status){
            this.seriesOptions=res.data.data.type
            for(var i=0;i<this.seriesOptions.length;i++){
                if(this.seriesOptions[i]['id'] == this.$route.query.typepid){
                  this.seriesValue = this.seriesOptions[i]['name_en']
                  this.seriesid = this.seriesOptions[i]['id']
                  this.colorOptions = this.seriesOptions[i].child
                  for(var x=0;x<this.colorOptions.length;x++){
                    if(this.colorOptions[x]['id'] == this.$route.query.typeid){
                      this.colorValue = this.colorOptions[x]['name_en']
                      this.colorid = this.colorOptions[x]['id']
                    }
                  }
                }
            }
          }         
      })
      axios.post('https://n.xilukeji.com/rwf/index.php/Shop/Shop/community_2023',json).then(res=>{
          // console.log(res)
          if(res.data.count == 0){
            this.caselist = res.data.info 
            this.total = 0
          }else{
            this.caselist = res.data.info
            this.total = res.data.count
          } 
          if(this.total<=this.per_page){
              this.display = 'none'
          }else{
            this.display = 'block'
          }  
      })
    }else{
      this.getcaselist()
    }
  },
  // updated(){
  //   this.getcaselist()
  // }
};
</script>
